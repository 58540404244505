import React, { useReducer, useContext, createContext, Dispatch } from 'react';

// 필요한 타입들을 미리 선언

type PermissionStatus = 'unavailable' | 'denied' | 'limited' | 'granted' | 'blocked';

// 상태를 위한 타입
type State = {
  camera: PermissionStatus;
  photoLibrary: PermissionStatus;
};

// 모든 액션들을 위한 타입
type Action = { type: 'SET_CAMERA'; camera: PermissionStatus } | { type: 'SET_PHOTOLIBRARY'; photoLibrary: PermissionStatus };

// 디스패치를 위한 타입 (Dispatch 를 리액트에서 불러올 수 있음), 액션들의 타입을 Dispatch 의 Generics로 설정
type PermissionDispatch = Dispatch<Action>;

// Context 만들기
const PermissionStateContext = createContext<State | null>(null);
const PermissionDispatchContext = createContext<PermissionDispatch | null>(null);

// 리듀서
function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'SET_CAMERA':
      return {
        ...state,
        camera: action.camera, // count가 자동완성되며, number 타입인걸 알 수 있습니다.
      };
    case 'SET_PHOTOLIBRARY':
      return {
        ...state,
        photoLibrary: action.photoLibrary, // text가 자동완성되며, string 타입인걸 알 수 있습니다.
      };
    default:
      throw new Error('Unhandled action');
  }
}

// SampleProvider 에서 useReduer를 사용하고
// SampleStateContext.Provider 와 SampleDispatchContext.Provider 로 children 을 감싸서 반환합니다.
export function PermissionProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(reducer, {
    camera: 'denied',
    photoLibrary: 'denied',
  });

  return (
    <PermissionStateContext.Provider value={state}>
      <PermissionDispatchContext.Provider value={dispatch}>{children}</PermissionDispatchContext.Provider>
    </PermissionStateContext.Provider>
  );
}

// state 와 dispatch 를 쉽게 사용하기 위한 커스텀 Hooks
export function usePermissionState() {
  const state = useContext(PermissionStateContext);
  if (!state) throw new Error('Cannot find SampleProvider'); // 유효하지 않을땐 에러를 발생
  return state;
}

export function usePermissionDispatch() {
  const dispatch = useContext(PermissionDispatchContext);
  if (!dispatch) throw new Error('Cannot find SampleProvider'); // 유효하지 않을땐 에러를 발생
  return dispatch;
}
