import { css, Global } from '@emotion/react';

const GlobalStyles = () => (
  <Global
    styles={css`
      * {
        box-sizing: border-box;
      }

      p {
        word-break: break-all;
      }

      html {
        font-size: 10px;
      }

      html,
      body {
        /* overflow: hidden; */
        touch-action: none;
        -webkit-overflow-scrolling: none;
        /* 이외의 브라우저 */
        overscroll-behavior: none;
        height: 100%;
        position: relative;
      }

      blockquote,
      body,
      button,
      code,
      dd,
      div,
      dl,
      dt,
      fieldset,
      form,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      input,
      legend,
      li,
      ol,
      p,
      pre,
      select,
      td,
      textarea,
      th,
      ul {
        margin: 0;
        padding: 0;
      }
      fieldset,
      img {
        border: 0;
      }
      dl,
      li,
      menu,
      ol,
      ul {
        list-style: none;
      }
      blockquote,
      q {
        quotes: none;
      }
      blockquote:after,
      blockquote:before,
      q:after,
      q:before {
        content: '';
        content: none;
      }
      button,
      input,
      select,
      textarea {
        vertical-align: middle;
        font-size: 100%;
      }
      button {
        border: 0;
        background-color: transparent;
        cursor: pointer;
      }
      table {
        border-collapse: collapse;
        border-spacing: 0;
      }
      body {
        max-width: 100%;
        overflow-x: hidden;
        -webkit-text-size-adjust: none;
        background-color: white;
      }
      input[type='checkbox'] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        cursor: pointer;
        outline: 0;
      }
      input[type='checkbox']:checked::after {
        display: block;
      }
      html input[type='button'],
      input[type='email'],
      input[type='password'],
      input[type='reset'],
      input[type='search'],
      input[type='submit'],
      input[type='tel'],
      input[type='text'] {
        -webkit-appearance: none;
        border: 0;
        border-radius: 0;
      }
      input[type='text']:focus {
        outline: 0;
      }
      input[type='search']::-webkit-search-cancel-button {
        -webkit-appearance: none;
      }
      body,
      button,
      input,
      select,
      td,
      textarea,
      th {
        font-size: 14px;
        line-height: 1.5;
        font-family: Apple SD Gothic Neo, Malgun Gothic, 맑은 고딕, sans-serif;
        font-weight: 400;
        color: #333;
      }

      a,
      a:active,
      a:hover {
        text-decoration: none;
      }
      address,
      caption,
      cite,
      code,
      dfn,
      em,
      var {
        font-style: normal;
        font-weight: 400;
      }
      input,
      textarea,
      button,
      select,
      a {
        -webkit-tap-highlight-color: transparent;
      }
    `}
  />
);

export default GlobalStyles;
