import GlobalStyles from 'components/GlobalStyles';
import Router from 'pages/Router';
import { SWRConfig } from 'swr';
import { PermissionProvider } from './context/PermissionContext';

function App() {
  return (
    <SWRConfig
      value={{
        revalidateOnFocus: false,
        shouldRetryOnError: false,
      }}>
      <GlobalStyles />
      <PermissionProvider>
        <Router />
      </PermissionProvider>
    </SWRConfig>
  );
}

export default App;
