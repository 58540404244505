export default {
  get(key) {
    return JSON.parse(window.localStorage.getItem(key));
  },

  set(key, value) {
    const stringToValue = JSON.stringify(value || null);
    window.localStorage.setItem(key, stringToValue);
    return JSON.parse(stringToValue);
  },

  asyncSet(key, value) {
    const stringToValue = JSON.stringify(value || null);
    return Promise.resolve().then(() => {
      window.localStorage.setItem(key, stringToValue);
    });
  },

  remove() {
    const introShow = this.get('introShow');
    const loginId = this.get('loginId');
    localStorage.clear();
    if (introShow) {
      this.set('introShow', true);
    }
    if (loginId) {
      this.set('loginId', loginId);
    }
  },
};
