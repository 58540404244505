import PrivateRoute from 'components/PrivateRoute';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

const Example = () => {
  return <div>Hello World!</div>;
};

const routes = [{ isPrivate: false, path: '/', element: <Example /> }];

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        {routes.map(route =>
          route.isPrivate ? (
            <Route path={route.path} element={<PrivateRoute>{route.element}</PrivateRoute>} key={route.path} />
          ) : (
            <Route path={route.path} element={route.element} key={route.path} />
          ),
        )}
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
